//
//
//
//
//

  import OrderList from "@/page/admin/myOrder/flightOrder/component/list/1.0.0/index.vue";
export default {
  data() {
    return {
      statusList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 41,
          label: "退票中"
        },
        {
          value: 45,
          label: "退票成功"
        },
        // {
        //   value: 45,
        //   label: "已退票"
        // },
        {
          value: 46,
          label: "退票失败"
        }
      ]
    };
  },
  components: {
    OrderList
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
