//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "WarningBox",
  data() {
    return {};
  },
  props: {
    search: Function,
    loading: Boolean
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
