import orderSearch from "@/page/admin/myOrder/component/orderSearch";
import orderList from "@/lib/data-service/haolv-default/consumer_air_ticket_orderList";
import cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import WarningBox from "@/page/admin/myOrder/component/warningBox";
import { getWeekDay } from "@/lib/utils/common";
export default {
  data() {
    return {
      formData: {
        type: this.type,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        orderStatus: "",
        evectionType: "",
        orderNo: '',
        psgName: '',
      },
      evectionNo: "",
      orderNo: "",
      isShow: false,
      flightList: [],
      getWeekDay: getWeekDay,
      loading: false,

      evectionTypeList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 1,
          label: "个人预定"
        },
        {
          value: 2,
          label: "出差申请"
        }
      ],
    };
  },
  components: {
    orderSearch,
    WarningBox,
  },
  created() {},
  mounted() {},
  activated() {
    this.getList();
  },
  props: {
    type: String, //0.机票订单 1.退票单 2.该签单 3.退款单
    statusList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    orderName() {
      let type = this.type;
      let name = ''
      switch (type) {
        case "0":
          name = '订单号';
          break;
        case "1":
          name = '退票单号';
          break;
        case "2":
          name = '改签单号';
          break;
        default:
          break;
      }
      return name
    },
    typeUserName() {
      let type = this.type
      let name = ''
      if (type === '0' || type === 0) {
        name = '出行人'
      } else if (type === '1' || type === 1) {
        name = '退票乘客'
      } else {
        name = '改签乘客'
      }
      return name
    }
  },
  methods: {
    returnStatus(item) {
      if ((this.type === '0' && item.orderStatus === '已出票') ||
          (this.type === '2' && item.orderStatus === '改签完成') ||
          (this.type === '2' && item.orderStatus === '改签成功') ||
          (this.type === '3' && item.orderStatus === '退款完成') ||
          (this.type === '1' && item.orderStatus === '已退票')) {
        return 'status2' // 绿色
      } else if ((this.type === '0' && item.orderStatus === '出票失败') ||
          (this.type === '0' && item.orderStatus === '已取消') ||
          (this.type === '2' && item.orderStatus === '改签失败') ||
          (this.type === '3' && item.orderStatus === '退款失败') ||
          (this.type === '1' && item.orderStatus === '退票失败')) {
        return 'status3' // 红色
      } else if ((this.type === '0' && item.orderStatus === '待支付') ||
          (this.type === '0' && item.orderStatus === '超标审批中') ||
          (this.type === '0' && item.orderStatus === '待出票') ||
          (this.type === '1' && item.orderStatus === '退票中') ||
          (this.type === '1' && item.orderStatus === '未退票 待审批')) {
        return 'status1' // 黄色
      } else if ((this.type === '2' && item.orderStatus === '已取消') ||
          (this.type === '3' && item.orderStatus === '已取消') ||
          (this.type === '1' && item.orderStatus === '已取消')) {
        return 'status4' // 灰色
      } else {
        return 'status4'
      }
    },




    //退票
    refundTicket(orderNo) {
      this.orderNo = orderNo;
      this.refund(orderNo, 2);
    },
    goDetail(val, psgNoList) {
      let orderNo = val;
      var goPage = (name, psgNoList) => {
        this.$router.push({
          name: name,
          query: {
            orderNo: orderNo,
            psgNoList,
          },
        });
      };
      let type = this.type;
      switch (type) {
        case "0":
          this.$router.push({
            name: 'admin-my-order-flight-detail',
            query: {
              orderNo: val.orderNo
            }
          })
          break;
        case "1":
          this.$router.push({
            name: 'admin-my-order-flight-refund-detail',
            query: {
              orderNo: val.orderNo,
            }
          })
          break;
        case "2":
          this.$router.push({
            name: 'admin-my-order-flight-change-detail',
            query: {
              orderNo: val.changeOrderNo,
              psgNoList: val.psgNo,
              oriOrderNo: val.orderNo
            }
          })
          break;
        case "3":
          this.$router.push({
            name: 'admin-my-order-flight-refund-form-detail',
            query: {
              orderNo: val.orderNo
            }
          })
          break;
        default:
          break;
      }
    },
    cancelRefund() {},
    cancelChange() {},
    payChange(item) {
      if (item.evectionType === '个人预定') {
        this.$router.push({
          name: "admin-travel-payment",
          query: { orderNo: item.changeOrderNo, /*evectionNo: item.evectionNo,*/ orderType: 1, pageType: 3 },
        });
      } else {
        this.$router.push({
          name: 'admin-pay',
          query: {
            orderNos: item.changeOrderNo,
            evectionNo: item.evectionNo,
            orderType: 1,
            pageType: 3
          },
        });
      }

    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getList();
    },
    cancel() {
      this.isShow = false;
    },
    goPay(item) {
      if (item.evectionType === 1) {
        this.$router.push({
          name: 'admin-personal-flight-submit-order',
          query: { orderNo: item.orderNo},
        });
      } else {
        this.$router.push({
          name: 'admin-pay',
          query: {
            evectionNo: item.evectionNo,
            orderNos: item.orderNo,
            flightOrderList: item.orderNo,
            orderType: 1
          },
        });
      }

    },
    cancelOrder(orderNo) {
      this.$confirm("确定要取消订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            this.loading = true
            let data = {
              orderNo
            };
            cancelOrder(data).then(res => {
              this.loading = false;
              this.$message({
                type: "success",
                message: '取消订单成功'
              });
              this.getList()
            });
          })
          .catch(() => {
            this.loading = false;
          });
    },
    refund(orderNo, orderStatus, evectionNo) {
      // this.isShow = false
      this.$router.push({
        name: "admin-my-order-flight-refund",
        query: {
          orderNo: orderNo,
          orderStatus: orderStatus
        },
      });
    },
    ticket(orderNo, evectionNo, evectionType) {
      // this.isShow = false
      this.$router.push({
        name: "admin-my-order-flight-change",
        query: {
          orderNo: orderNo,
          evectionNo: evectionNo,
          evectionType: evectionType === '出差申请' ? 2 : 1
        },
      });
    },
    getList() {
      this.loading = true;
      orderList(this.formData)
        .then((res) => {
          this.loading = false;
          this.flightList = res.pageResult.pageData;
          this.formData.total = res.pageResult.totalCount;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getOrderStatu(type) {
      let statuList = this.$attrs.statuList;
      let list = statuList.filter((item) => {
        return item.value === type;
      });
      return list[0];
    },
    handleCurrentChange(val) {
      this.formData.currentPage = val;
      this.getList();
    },
    search() {
      this.formData.currentPage = 1;
      this.getList();
    },
    react() {
      this.formData = {
        type: this.type,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        orderStatus: "",
        evectionType: "",
        orderNo: '',
        psgName: '',
      }
      this.search()
    },
  },
};
