//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "OrderSearch",
  data() {
    return {
      evectionTypeList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 1,
          label: "个人预定"
        },
        {
          value: 2,
          label: "出差申请"
        }
      ],
      orderStatusList: this.$attrs.statuList,
      type: this.$parent.type,
      status: ""
    };
  },

  props: {
    formData: Object,
    search: Function
  },
  methods: {
    submit() {
      this.$emit("update:formData", this.formData);
      this.search();
    }
  },
  mounted() {},
  activated() {},
  watch: {
    status(val) {
      //0.机票订单 1.退票单 2.该签单 3.退款单
      switch (this.type) {
        case "0":
          return (this.formData.orderStatus = val);
          break;
        case "1":
          return (this.formData.refStatus = val);
          break;
        case "2":
          return (this.formData.chaStatus = val);
          break;
        case "3":
          return (this.formData.reiStatus = val);
          break;
        default:
          break;
      }
    }
  },
  computed: {
    orderName() {
      let type = this.type;
      let name = ''
      switch (type) {
        case "0":
          name = '订单号';
          break;
        case "1":
          name = '退票单号';
          break;
        case "2":
          name = '改签单号';
          break;
        default:
          break;
      }
      return name
    },
  }
};
